<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="publisher")
			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Publisher Information <span v-if="publisher.id">({{ publisher.id }})</span>
									.card-toolbar
								.card-body
									.form-group
										label Name
										input.form-control(type="text", v-model="publisher.name")
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Panel Information
									.card-toolbar
								.card-body
									.row
										.col-sm-12
											.form-group
												label Panel URL
												input.form-control(type="text", v-model="publisher.panel_url")
									.row
										.col-sm-6
											.form-group
												label Username
												input.form-control(type="text", v-model="publisher.panel_username")
										.col-sm-6
											.form-group
												label Password
												input.form-control(type="text", v-model="publisher.panel_password")
						.col-md-6

							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Misc
									.card-toolbar
								.card-body
									.form-group
										label Comments
										textarea.form-control(v-model="publisher.comments", rows="5")

					.row
						.col-sm-12
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Postbacks
									.card-toolbar
								.card-body
									.row
										.col-sm-4
											.form-group
												label Postback Mode
												select.form-control(v-model="publisher.postback_mode")
													option(value="conversions") Conversions Only
													option(value="full") Full (Installs + Events)
											p.help-block
												span(v-if="publisher.postback_mode === 'conversions'") Report only conversions
												span(v-if="publisher.postback_mode === 'full'") Report install/events (100% risk)
										.col-sm-4
											.form-group
												label Risk (%)
												input.form-control(type="text", v-model="publisher.risk")
										.col-sm-4
											.form-group
												label Payout (%)
												input.form-control(type="text", v-model="publisher.payout_percent")
									.row
										.col-sm-12
											b-checkbox(v-model="publisher.support_advanced_privacy") Support Advanced Privacy
									table.table
										thead
											tr
												th Postback
												th(style="width: 140px;") Type
												th(style="width: 60px;") Active
												th.text-center(style="width: 50px;")
													a(href="javascript:void(0)", @click="addPostback()")
														i.la.la-plus
										tbody
											tr(v-if="publisher.postbacks.length === 0")
												td(colspan="4") No postbacks were defined
											tr(v-for="(p, i) in publisher.postbacks")
												td
													input.form-control(type="text", v-model="p.url", placeholder="https://...")
												td
													select.form-control(v-model="p.type", style="width: 155px;")
														option(v-for="o in postbackOptions" :value="o.v") {{ o.t }}
												td.text-center
													b-form-checkbox(v-model="p.enabled")
												td.text-center
													button.btn.btn-secondary(type="button", @click="publisher.postbacks.splice(i, 1)")
														i.la.la-times



</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";

async function loadRoute(vm, to, from, next){

	let publisher = {};
	if (to.name === 'publisher-edit') {
		let entityId = +to.params.id;
		try {
			publisher = await Vue.$dwData.publisher.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load publisher');
			return next(false);
		}
	} else {
		publisher = Vue.$dwData.publisher.newInstance();
	}
	// Vue.ovData.publisher._booleans.forEach(f => {
	// 	publisher[f] = !!publisher[f];
	// });
	return vm => {
		// vm.publisherOptions = publisherOptions;
		// vm.integrationTypeOptions = integrations;
		vm.publisher = publisher;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'PublisherForm',
	data(){
		return {
			busy: true,
			publisher: null,
			postbackOptionsConversions: [
				{v: 'conversion', t: 'conversion'},
				{v: 'ap:conversion', t: 'AP Conversion'},
				// {v: 'reject', t: 'Reject'},
				// {v: 'ap:reject', t: 'AP Reject'},
			],
			postbackOptionsFull: [
				{v: 'install', t: 'Install'},
				{v: 'ap:install', t: 'AP Install'},
				{v: 'event', t: 'Event'},
				{v: 'ap:event', t: 'AP Event'},
				// {v: 'reject', t: 'Reject'},
				// {v: 'ap:reject', t: 'AP Reject'},
			],
		};
	},
	computed: {
		postbackOptions(){
			if (!this.publisher){
				return [];
			}
			return (this.publisher.postback_mode === 'conversions') ? this.postbackOptionsConversions : this.postbackOptionsFull;
		}
	},
	methods: {

		addPostback(){
			this.publisher.postbacks.push({
				url: '',
				type: 'install',
				enabled: true
			});
		},

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.publisher);
			try {
				let resp = await this.$api.post('publishers/save', data);
				this.busy = false;
				this.$notify.success('Publisher has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'publisher-list'});
				} else {
					this.publisher = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Publishers', name: 'publisher-list'},
			{title: this.publisher && this.publisher.id ? 'Edit Publisher' : 'Add Publisher'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'publisher-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
